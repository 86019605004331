export enum GTM_EVENT_NAME {
	VIRTUAL_PAGE_VIEW = 'peaz_virtual_page_view',
	USER_SIGN_IN_SUCCESS = 'peaz_user_sign_in_success',
	USER_SIGN_IN_FAILURE = 'peaz_user_sign_in_failure',
	USER_FIRST_SIGN_IN = 'peaz_user_first_sign_in',
	SNS_CONNECT_ATTEMPT = 'peaz_sns_connect_attempt',
	SNS_CONNECT_SUCCESS = 'peaz_sns_connect_success',
	USER_REGISTER_SUCCESS = 'peaz_user_register_success',
	USER_REGISTER_FAILURE = 'peaz_user_register_failure',
	DRAFT_EVENT_CREATED = 'peaz_draft_event_created',
	TICKET_ADDED = 'peaz_ticket_added',
	FIRST_EVENT_PUBLISHED = 'peaz_first_event_published',
	EVENT_PUBLISHED = 'peaz_event_published',
	DISPLAY_PRE_PUBLISH_MODAL = 'peaz_display_pre_publish_modal',
	DISPLAY_PUBLISH_SUCCESS_MODAL = 'peaz_display_publish_success_modal',
	DOWNLOAD_ATTENDEE_INFO = 'peaz_download_attendee_info',
	MESSAGE_SENT = 'peaz_message_sent',
	CANCEL_ORDER = 'peaz_cancel_order',
	CHECK_IN_SUCCESS_ATTENDEE_DASHBOARD = 'peaz_check_in_success_attendee_dashboard',
	CHECK_IN_UNDO_SUCCESS = 'peaz_check_in_undo_success',
	DELETE_QUESTIONNAIRE_SUCCESS = 'peaz_delete_questionnaire_success',
	PREVIEW_QUESTIONNAIRE = 'peaz_preview_questionnaire',
	EDIT_QUESTIONNAIRE = 'peaz_edit_questionnaire',
	ENABLE_QUESTIONNAIRE = 'peaz_enable_questionnaire',
	DISABLE_QUESTIONNAIRE = 'peaz_disable_questionnaire',
	CREATE_QUESTIONNAIRE = 'peaz_create_questionnaire',
	VIEW_SALES_ANALYTICS = 'peaz_view_sales_analytics',
	CHECK_IN_QR_SUCCESS = 'peaz_check_in_qr_success',
	VIEW_USER_PROFILE = 'peaz_view_user_profile',
	EDIT_USER_PROFILE_SUCCESS = 'peaz_edit_user_profile_success',
	MESSAGE_FOLLOWERS_SUCCESS = 'peaz_message_followers_success',
	SHARE_PROFILE_SUCCESS = 'peaz_share_profile_success',
	VIEW_TICKET_SUCCESS = 'peaz_view_ticket_success',
	MY_TICKETS_SUCCESS = 'peaz_my_tickets_success',
	VIEW_PRICING_PAGE_SUCCESS = 'peaz_view_pricing_page_success',
	CALCULATE_YOUR_TICKET_FEES_SUCCESS = 'peaz_calculate_your_ticket_fees_success',
	PAYMENT_SUCCESS = 'peaz_payment_success',
	PAYMENT_FAILURE = 'peaz_payment_failure',
	ENTER_PAYMENT_INFO = 'peaz_enter_payment_info',
	APPLY_DISCOUNT_CODE_SUCCESS = 'peaz_apply_discount_code_success',
	CHECK_OUT_WITH_QUESTIONNAIRE_SUCCESS = 'peaz_check_out_with_questionnaire_success',
	CHECK_OUT_WITH_TOKEN_GATE_SUCCESS = 'peaz_check_out_with_token_gate_success',
	CHECK_OUT_WITH_TOKEN_GATE_FAILURE = 'peaz_check_out_with_token_gate_failure',
	VIEW_TERMS_PAGE_SUCCESS = 'peaz_view_terms_page_success',
	VIEW_ABOUT_US_PAGE_SUCCESS = 'peaz_view_about_us_page_success',
	SHARE_EVENT_SUCCESS = 'peaz_share_event_success',
}

export enum GTM_DRAFT_EVENT_CREATED_PARAMS {
	GENERATE_WITH_AI = 'generate_with_ai',
	MANUAL_SKIP = 'manual_skip',
	DUPLICATED = 'duplicated',
}

export enum GTM_EVENT_PUBLISHED_PARAMS {
	ONLINE = 'online',
	IN_PERSON = 'in_person',
}

export enum GTM_TICKET_ADDED_PARAMS {
	FREE = 'free',
	PAID = 'paid',
	TOKEN_GATED = 'token_gated',
}

export enum GTM_DISPLAY_PRE_PUBLISH_MODAL_PARAMS {
	FORGOT_TICKET = 'forgot_ticket',
	CONFIRM_PUBLISH = 'confirm_publish',
}

export enum GTM_DISPLAY_PUBLISH_SUCCESS_MODAL_PARAMS {
	VIEW_EVENT_PAGE = 'view_event_page',
	BACK_TO_EDIT = 'back_to_edit',
}