<script setup lang="ts">
import type UserProfileInfoSkeletonLoader from '@/components/loaders/UserProfileInfoSkeletonLoader.vue';
import type { Follower, Profile, Social, SocialProfile } from '@/types/profile';

const props = defineProps({
	tab: {
		type: String,
		default: null,
	},
	eventId: {
		type: String || Number,
		default: null,
	},
});

const route = useRoute();
const router = useRouter();
const profileStore = useProfileStore();
const notificationStore = useNotificationStore();
const { isMobile } = useWindowResize();

const tabs = [
	{
		title: 'Attending',
		name: MY_ACCOUNT_TABS.ATTENDING,
	},
	{
		title: 'Hosting',
		name: MY_ACCOUNT_TABS.HOSTING,
	},
];

const activeTab = ref<string>(props.tab || MY_ACCOUNT_TABS.ATTENDING);
const isShowEditDialog = ref(false);
const isShowMessageDialog = ref(false);
const isShowNoFollowerDialog = ref(false);
const myAccountAttendingRef = ref();
const followerNumber = ref();
const followers = ref<Follower[]>([]);
const currentPage = ref(1);

const profile = reactive<Partial<Profile>>({
	profileName: '',
	profileImageUrl: '',
	groupDescription: '',
	groupCoverImageUrl: '',
});

const groupId = computed(() => profileStore.profileGroupId);
const profileId = computed(() => profileStore.profile?.id);
const isLoading = computed(() => profileStore.isLoading);

const sharedProfileUrl = computed(() => {
	return getFullUrlPath(`/user/${profileId.value}`);
});

function handleOnDialogClose() {
	isShowEditDialog.value = false;
	isShowMessageDialog.value = false;
}

function handleShowMessageDialog() {
	const follower = profileStore.profileGroup?.stats?.followers;
	if (follower) {
		isShowMessageDialog.value = true;
	} else {
		isShowNoFollowerDialog.value = true;
	}
}

function generateSocialPayload(data: Social): SocialProfile[] {
	return Object.entries(data).map(([sns, url]) => {
		return {
			sns,
			profileUrl: url,
		};
	});
}

async function handleOnSendMessage(subject: string, body: string) {
	if (!groupId.value) {
		if (profileStore.errorMessage) {
			notificationStore.showErrorNotification(profileStore.errorMessage);
		}
		isShowMessageDialog.value = false;
		return;
	}

	await profileStore.sendMessageToFollowers(
		groupId.value,
		{
			body,
			subject,
		},
	);

	if (profileStore.errorMessage) {
		notificationStore.showErrorNotification(profileStore.errorMessage);
	} else {
		notificationStore.showSuccessNotification('Successfully sent the message.');
	}

	isShowMessageDialog.value = false;
}

async function handleUpdateProfile(data: Profile) {
	if (!groupId.value) {
		notificationStore.showErrorNotification(profileStore.errorMessage);
		isShowEditDialog.value = false;
		return;
	}

	const { hasDeletedImage, groupCoverImageFile, profileImageFile, ...newData } = data;

	// Check for profile name or description changes
	if (
		newData.groupDescription !== profile.groupDescription ||
  newData.profileName !== profile.profileName
	) {
		await profileStore.updateGroupInfo(groupId.value, {
			description: newData.groupDescription || ' ',
			// Always updated current group name following user nickname behind the scene
			...(newData.profileName ? {name: newData.profileName} : {}),
		});
	}

	// Check for social changes
	if (profileId.value && data.socials) {
		const socialPayload = generateSocialPayload(data.socials);
		await profileStore.updateSocialProfile(profileId.value, socialPayload);
	}

	// Check for profile name or avatar image changes
	if (profileId.value && (newData.profileName !== profile.profileName)) {
		await profileStore.updateProfile(profileId.value, {
			name: newData.profileName,
			file: profileImageFile || null,
		});
	} else if (profileId.value && (newData.profileImageUrl !== profile.profileImageUrl)) {
		await profileStore.updateProfile(profileId.value, {
			name: newData.profileName,
			file: profileImageFile || null,
		});
	}

	// Check for group cover image changes
	if (groupCoverImageFile && !hasDeletedImage) {
		await profileStore.uploadGroupCoverImage(groupId.value, groupCoverImageFile);
	} else if (hasDeletedImage) {
		await profileStore.deleteGroupCoverImage(groupId.value);
	}

	if (profileStore.errorMessage) {
		notificationStore.showErrorNotification(profileStore.errorMessage);
	} else if (profileStore.social.errorMessage) {
		notificationStore.showErrorNotification(profileStore.social.errorMessage);
	} else {
		notificationStore.showSuccessNotification('Successfully updated your profile.');
		await profileStore.getProfileGroupInfo(groupId.value);
	}
	isShowEditDialog.value = false;
}

async function handleOnFetchFollowerList($state: any) {
	const isComplete = profileStore.follower.data?.paginationInfo.totalItems === followers.value.length;
	if (!profileStore.profileGroupId || isComplete) {
		$state.complete();
		return;
	}

	await profileStore.getFollowerList(
		profileStore.profileGroupId,
		{
			itemPerPage: 8,
			currentPage: currentPage.value,
			sortBy: 'desc(pod_user.created)',
		},
	);

	if (!profileStore.follower.data?.data.length) {
		$state.complete();
		return;
	}

	followers.value.push(...profileStore.follower.data.data);
	$state.loaded();
	currentPage.value++;

	if (profileStore.follower.errorMessage) {
		notificationStore.showErrorNotification(profileStore.follower.errorMessage);
	}
}

watch(() => props.tab, (newTab) => {
	if (newTab) {
		activeTab.value = newTab.toString();
	}
});

watch(() => groupId.value, async (newId) => {
	if (newId) {
		// Update group info when refresh the page
		await profileStore.getProfileGroupInfo(newId);
	}
});

// Watch for changes in the profile data and update formData
watch(() => profileStore.profile, async (newData) => {
	if (newData) {
		profile.profileName = newData.name;
		profile.profileImageUrl = newData.avatarLargeUrl;

		// Fetch social media profile
		await profileStore.getSocialProfile(newData.id);
	}
});

// Watch for changes in the group data and update profile
watch(() => profileStore.profileGroup, (newData) => {
	if (newData) {
		profile.groupDescription = newData.description;
		profile.groupCoverImageUrl = newData.cover;
		followerNumber.value = newData.stats.followers;
	}
});

// Reset infinite loading state when follow status changes
watch(() => followerNumber.value, (newValue) => {
	if (newValue) {
		followers.value = [];
		currentPage.value = 1;
	}
});

onMounted(async () => {
	// Open view ticket detail
	const { eventId } = route.query;
	if (eventId) {
		// Remove query string
		router.push({ query: {}});
		// Open view ticket detail on sidebar
		myAccountAttendingRef.value.viewTicketDetail(Number(eventId));
	}

	if (profileId.value) {
		await profileStore.getProfileGroupByUserId(profileId.value);
		await profileStore.getSocialProfile(profileId.value);
	}

	if (groupId.value) {
		// Update group info when change account
		await profileStore.getProfileGroupInfo(groupId.value);
		followerNumber.value = profileStore.profileGroup?.stats.followers;
	}

	if (profileStore.profile) {
		profile.profileName = profileStore.profile.name;
		profile.profileImageUrl = profileStore.profile.avatarLargeUrl;
	}
});
</script>
<template>
  <div class="profile-page-container page">
    <UserProfileHeader
      :name="profile.profileName"
      :profile-image-url="profile.profileImageUrl"
      :cover-image-url="profile.groupCoverImageUrl"
      :is-loading="isLoading"
    >
      <UserProfileInfoSkeletonLoader
        v-if="isLoading"
        :action-count="3"
      />
      <template v-else>
        <p
          v-if="profile.groupDescription"
          class="text-md text-regular"
        >
          {{ profile.groupDescription }}
        </p>
        <SocialList />
        <FollowerList
          :follower-number="followerNumber"
          :followers="followers"
          @on-fetch-data="handleOnFetchFollowerList"
        />
        <div class="action">
          <BaseButton
            variant="outlined"
            color="gray"
            size="sm"
            :disabled="isLoading"
            @click="isShowEditDialog = true"
          >
            <template #leftIcon>
              <EditIcon />
            </template>
            <p v-if="!isMobile">
              Edit profile
            </p>
          </BaseButton>
          <BaseButton
            variant="outlined"
            color="gray"
            size="sm"
            :disabled="isLoading"
            @click="handleShowMessageDialog"
          >
            <template #leftIcon>
              <MailIcon />
            </template>
            <p v-if="!isMobile">
              Message followers
            </p>
          </BaseButton>
          <ShareButton
            :url="sharedProfileUrl"
            :user-id="profileStore.profileData?.id"
            :group-id="profileStore.profileGroupData?.id"
          >
            <p v-if="!isMobile">
              Share
            </p>
          </ShareButton>
        </div>
      </template>
    </UserProfileHeader>
    <BaseAlert
      class="info-banner"
      :type="ALERT_TYPE.INFO"
    >
      <span class="text-sm text-semibold">
        Follow these
        <a
          href="https://help-attendee.peatix.com/en/support/solutions/articles/44002540319"
          target="_blank"
        >steps</a>
        to access Japan events and tickets.
      </span>
    </BaseAlert>
    <div class="tabs">
      <VTabs
        v-model="activeTab"
        centered
        grow
        height="32px"
      >
        <VTab
          v-for="tabItem in tabs"
          :key="tabItem.name"
          :value="tabItem.name"
          :ripple="false"
        >
          {{ tabItem.title }}
        </VTab>
      </VTabs>
      <VWindow v-model="activeTab">
        <VWindowItem
          :value="MY_ACCOUNT_TABS.ATTENDING"
        >
          <MyAccountAttending
            v-if="activeTab === MY_ACCOUNT_TABS.ATTENDING"
            ref="myAccountAttendingRef"
            :user-id="profileId"
            :disabled="isLoading"
            :is-profile-loading="isLoading"
          />
        </VWindowItem>
        <VWindowItem
          :value="MY_ACCOUNT_TABS.HOSTING"
        >
          <MyAccountHosting
            v-if="activeTab === MY_ACCOUNT_TABS.HOSTING"
            :user-id="profileId"
            :group-id="groupId"
            :is-profile-loading="isLoading"
          />
        </VWindowItem>
      </VWindow>
    </div>
    <SendMessageDialog
      receiver="All followers"
      :is-show="isShowMessageDialog"
      :disabled="isLoading"
      @on-close="handleOnDialogClose"
      @on-send="handleOnSendMessage"
    />
    <ConfirmDialog
      :is-show="isShowNoFollowerDialog"
      confirm-button-name="Ok, I got it"
      hide-cancel-button
      @on-close="isShowNoFollowerDialog = false"
      @on-confirm="isShowNoFollowerDialog = false"
    >
      <template #header>
        <InfoCircleIcon
          class="icon-header-confirm-dlg icon-info"
          :color="ICON_COLOR.PRIMARY"
        />
      </template>
      <template #title>
        No followers at the moment
      </template>
      <template #content>
        <p class="text-sm text-regular">
          You currently do not have any followers to send your message to.
        </p>
      </template>
    </ConfirmDialog>
    <ProfileDialog
      :is-show="isShowEditDialog"
      :disabled="profileStore.isLoading"
      @on-close="isShowEditDialog = false"
      @on-update="handleUpdateProfile"
    />
  </div>
</template>
<style lang="scss" scoped>
.profile-page-container {
	margin: auto;
}

.follower-btn {
	width: fit-content;
	margin: auto;
	padding: 0;
}

.action {
	display: flex;
	gap: spacings-get(4);
	margin: auto;

	@include media-query-max(mobile) {
		button {
			padding: spacings-get(2);
		}
	}
}

.info-banner {
	margin-bottom: spacings-get(6);

	@include media-query-max(mobile) {
		margin: 0 spacings-get(4) spacings-get(6);
	}
}

.tabs {
	@include media-query-max(mobile) {
		padding: 0 spacings-get(4);
	}
}
</style>